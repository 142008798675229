@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@layer utilities {
  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  .form-group {
    margin: 1rem 0;
  }

  .pagination-container {
    display: flex;
    list-style-type: none;
  }
  .pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 7px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
  }
  .pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  .pagination-container .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
  .pagination-container .pagination-item.selected {
    background-color: #1f2937;
    color: #f9fafb;
  }
  .pagination-container .pagination-item .arrow::before {
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    position: relative;
    /* By using an em scale, the arrows will size with the font */
    content: "";
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }
  .pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }
  .pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
  }
  .pagination-container .pagination-item.disabled {
    pointer-events: none;
  }
  .pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }
  .pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
  }

  .nav-tabs .discover-nav-link.active {
    color: white;
    background-color: #2a5269;
  }
}

ul li {
  padding: 5px;
  margin-top: 5px;
}

.border-box {
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
}

.church-management-search-box {
  margin: 30px auto;
  width: 500px;
}

.church-management-search-bar {
  width: 450px !important;
}

.search-results-container .search-filters {
  display: inline-block;
  /* width: 25%; */
  padding: 0px 20px;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  transition-duration: 150ms;
}

.search-results-container .sf-hidden {
  display: none;
  opacity: 0;
  width: 0;
  /* height: 0px !important; */
}

.search-results-container .search-filters .close-btn {
  float: right;
  margin-top: -20px;
}

.search-filters form {
  /* display: flex; */
}

.search-filter-advanced-button .flex {
  display: block !important;
}

.search-filters form input,
.search-filters form select {
  margin-top: 10px;
}

.search-results-container .search-results {
  display: inline-block;
  border-left: 2px solid #e5e7eb;
  width: 75%;
}

.search-results-container .checkbox-label {
  display: block;
  margin-left: 25px;
}

.search-results-container input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}
.search-results-container input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #555555;
  border-radius: 3px;
  background-color: white;
}
.search-results-container input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.advance-search-button button {
  display: flex;
  margin: auto !important;
}

.search-filter {
  display: inline-flex;
}

.selection-box-with-add-new {
  display: flex;
}
.selection-box-with-add-new button {
  padding: 10px 15px;
  margin-left: 5px;
  font-size: 18px;
}

.selection-with-add-new-loader .animate-spin {
  margin: 10px 50px;
}

.ql-disabled {
  background: #dcdcdc !important;
  cursor: not-allowed !important;
}

.ql-container {
  height: 200px !important;
}

.ql-editor {
  max-height: 200px !important;
}

.advanced-search-selections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 10px;
  margin-left: 10px;
}

.advanced-search-selections .selection-item {
  background: #198754;
  border-radius: 7px;
  padding: 5px 12px;
  color: white;
  font-size: 12px;
  margin: 5px 5px;
  cursor: pointer;
}

.advanced-search-selections .close {
  margin-left: 10px;
}

.connecting-people-church {
  text-align: center;
  padding: 20px 10px;
}

.ql-toolbar {
  border: 0 !important;
}
.ql-container {
  border: 0 !important;
}

.bible-container {
  width: 100%;
}
.bible-loader {
  position: absolute;
}

.church-favorite-button {
  margin-right: 10px;
}

.selection-box-selected-item {
   background: #9ca3af;
   width: fit-content;
   padding: 3px 16px;
   border-radius: 115px;
   display: inline-block;
   margin-bottom: 5px;
   margin-right: 5px;
}

.selected-item-remove-box {
   display: inline;
   width: fit-content;
   cursor: pointer;
   margin-left: 8px;
}

.selection-box-selected-item  .small-text {
  font-size: 11px !important;
  display: inline;
}
 
/* .selection-box-selected-item:hover {
   border: 1px solid #555555;
} */

.church-miles-corner {
  /* display: flex; */
  position: absolute;
  margin-top: -10px;
  margin-left: -5px;
  background: #196b87;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;
  color: white;
  font-size: 10px;
}

.advance-search-suggestions {
  border: 1px solid #cbcbcb;
  position: absolute;
  background: #f4f4f4;
  z-index: 100;
}

.advance-search-suggestions ul li {
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.advance-search-suggestions ul li:hover {
  background: #cbcbcb;
}

.global-form-layout {
  padding: 1px 10%;
}

.reset-advance-search {
  margin-right: 10px;
}

.discover-random-showing {
  padding: 10px;
  margin-left: 10px;
  text-align: center;
  color: #6c757d;
}

.discover-random-showing .click-here {
  color: #2979ff;
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 240px) {
  .ql-container {
    height: 150px !important;
  }
  .bible-container {
    height: 270px !important;
  }
}

@media (min-width: 960px) {
  .ql-container {
    height: 200px !important;
  }
  .bible-container {
    height: 340px !important;
  }
}

@media (min-width: 1240px) {
  .ql-container {
    height: 260px !important;
  }
  .bible-container {
    height: 480px !important;
  }
}

/* Web */
@media (max-width: 1920px) {
  .church-detail-church-title {
    margin-top: -10px;
  }

  .footer-button-container {
    display: flex;
  }

  .search-results-container .sf-available {
    display: inline-block;
    opacity: 1;
    width: 25%;
  }

  .search-results-container {
    display: flex;
  }

  .search-filter-advanced-button {
    display: inline-flex;
  }

  .church-details-video-left {
    width: 70% !important;
  }

  .church-details-video-right {
    width: 30% !important;
  }

  .advance-search-suggestions {
    width: 200px;
  }
}

/* Mobile */
@media (max-width: 764px) {
  .ql-container {
    height: 200px !important;
  }
  .bible-container {
    height: 320px !important;
  }

  .footer-button-container {
    display: inline;
  }

  .footer-button {
    width: 98%;
    margin: auto;
    margin-top: 10px;
    margin-left: 0px !important;
  }

  .search-results-container {
    display: inline;
  }

  .search-results-container .search-results {
    width: 100% !important;
  }

  .search-results-container .sf-available {
    /* display: inline-block; */
    opacity: 1;
    width: 100%;
  }

  .search-results-container .sf-hidden {
    display: none !important;
  }

  .search-filter-advanced-button {
    display: flex;
  }

  .search-filter-advanced-button button {
    width: 95%;
    margin: auto;
  }

  .church-details-video-left {
    width: 100% !important;
  }

  .church-details-video-right {
    width: 100% !important;
  }

  .advance-search-suggestions {
    width: 330px;
  }
}
